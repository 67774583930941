import './App.css';
import LoginButton from './LoginButton.js'
import LogoutButton from './LogoutButton.js'
import UserProfile from './UserProfile.js'
import Box from '@mui/material/Box';
import { useAuth0 } from "@auth0/auth0-react";


function App() {

  const { user, isAuthenticated } = useAuth0;

  return (
    <Box className="App">
      <header className="App-header">
        Hello, Auth!
        <p/>
        <UserProfile/>
        <LoginButton/>
        <LogoutButton/>
      </header>
    </Box>
  );
}

export default App;
